import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../../components/seo"
// import { Link } from "@reach/router"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { StaticImage } from "gatsby-plugin-image"
import PageTitle from "../../components/shared/PageTitle"
import defaultImage from "../../images/unsplash/chris-liverani-5oZ9uVx7buc-unsplash.jpg"
import { LinkToContent } from ".."

const RegistrationButton = ({ title, url, imagePath }) => {
  return (
    <Link
      to={url}
      className="relative shadow-md overflow-hidden no-underline group text-white hover:text-secondary bg-primary hover:bg-logoLightBlue text-6xl text-center font-bold tracking-wid px-10 h-24 flex justify-center items-center"
    >
      {title}
    </Link>
  )
}

const RegistrationPage = ({ data }) => {
  const mainContentDocument = data?.contentfulRegistrationPage?.mainContent

  return (
    <div className=" grid">
      <SEO
        title="Registration"
        keywords={[`Sioux Lookout`, `hockey`, `Registration`, `info`]}
      />
      <PageTitle className="my-10">Registration</PageTitle>

      {/* <div
        className="mt-10 break-words"
        style={{ maxWidth: "80%", justifySelf: "center" }}
      >
        {renderRichText(mainContentDocument)}
      </div> */}
      <div className="grid w-full justify-items-stretch">
        {/* <RegistrationButton
          title="Players"
          url="/registration/players"
          imagePath="../../images/unsplash/chris-liverani-5oZ9uVx7buc-unsplash.jpg"
        /> */}
        {/* <RegistrationButton title="Volunteers" url="/registration/volunteers" /> */}
        <div className="grid gap-y-14 mb-14 justify-center">
          <LinkToContent
            linkText="Players"
            linkTo="/registration/players"
            className="w-full text-center px-10"
          />
          <LinkToContent
            linkText="Volunteers"
            linkTo="/registration/volunteers"
            className="w-full text-center px-10 "
          />
        </div>
      </div>

      {/* <div>Button(s) for other types of registration (e.g. tournaments)</div> */}

      <p style={{ textAlign: "center" }}>
        <Link to="/contact">Contact us</Link> for more information.
      </p>
    </div>
  )
}

export default RegistrationPage

export const pageQuery = graphql`
  query registrationQuery {
    registrationImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrationForm: contentfulDocument(
      title: { eq: "SLMHA 2020 2021 Season Registration" }
    ) {
      file {
        file {
          url
        }
      }
    }
    contentfulRegistrationPage {
      mainContent {
        raw
      }
    }
  }
`
